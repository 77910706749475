<template>
    <div class="total-result">
        <label>ผลการค้นหา</label>
        <span>พบสินค้า <i>{{ totalResult }}</i> รายการ</span>
    </div>
</template>

<script>
export default {
	props: {
        totalResult: [ Number, String ]
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.total-result {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;

    label {
        font-size: 14px;
        padding: 3px 20px;
        font-family: 'Open Sans', 'Kanit';
        margin: 0;
        color: #555;
        background: $mainColor;
        font-weight: bold;
        text-align: center;
        @include borderRadius(1000px);
    }

    span {
        margin-left: 15px;
        flex: 1;
        font-size: 13px;
        color: #000;

        i {
            font-weight: bold;
            font-style: normal;
            color: #cc0000;
        }
    }
}
</style>