<template>
    <div class="form">
        <div class="field">
            <dropdown
                v-model="formSearchTire.width"
                placeholder="ความกว้าง"
                :classes="{ input: [ 'text-center' , 'input-option' ] }"
                :options="option.width"
                @change="changeTireSizeOption()">
            </dropdown>
        </div>
        <div class="field">
            <dropdown
                v-model="formSearchTire.ratio"
                placeholder="ซีรีย์"
                :classes="{ input: [ 'text-center' , 'input-option' ] }"
                :options="option.ratio"
                @change="changeTireSizeOption()">
            </dropdown>
        </div>
        <div class="field">
            <dropdown
                v-model="formSearchTire.diameter"
                placeholder="ขอบล้อ"
                :classes="{ input: [ 'text-center' , 'input-option' ] }"
                :options="option.diameter"
                @change="changeTireSizeOption()">
            </dropdown>
        </div>
        <div class="field">
            <button class="btn btn-main btn-search" @click="searchTire()">ค้นหา</button>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import MasterService from '@/services/masterService';

export default {
    data() {
        return {
            option: {
                width: [],
                ratio: [],
                diameter: []
            },
            formSearchTire: this.initFormSearchTire()
        };
    },
    mounted() {
        this.initTireOption();
    },
    methods: {
        searchTire() {
            this.$emit('searchTire', this.formSearchTire);
        },
        initFormSearchTire() {
            return {
                width: '',
                ratio: '',
                diameter: ''
            }
        },
        async initTireOption() {
            const result = await MasterService.getAllTireSizeOption();
            
            this.option.width = Helper.setupOption(result.data.width);
            this.option.ratio = Helper.setupOption(result.data.ratio);
            this.option.diameter = Helper.setupOption(result.data.diameter);
        },
        async changeTireSizeOption() {
            setTimeout(async () => {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getTireSizeByCondition(this.formSearchTire);
                loader.hide();

                this.option.width = Helper.setupOption(result.data.width);
                this.option.ratio = Helper.setupOption(result.data.ratio);
                this.option.diameter = Helper.setupOption(result.data.diameter);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -5px;

    .field {
        width: 25%;
        padding: 0 5px;

        @media only screen and (max-width: $screenMedium) {
            width: 50%;
            padding: 0 5px 10px;
        }

        .btn-search  {
            width: 100%;
            height: 36px;
            font-size: 15px;
            display: block;
        }
    }
}

::v-deep .form-option {
    .input-option {
        border: none;
        font-size: 15px;
        height: 36px;

        @media only screen and (max-width: $screenMedium) {
            font-size: 14px;
        }
    }
}
</style>