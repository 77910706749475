<template>
    <section class="product-search">
        <h2>ค้นหายางรถยนต์ ตามขนาด</h2>
        <product-search-form @searchTire="searchTire($event)" class="mt-3"></product-search-form>
    </section>
</template>

<script>
import ProductSearchForm from '@/components/gadget/ProductSearchForm';

export default {
    components: {
        ProductSearchForm
    },
    methods: {
        searchTire(value) {
            this.$emit('searchTire', value);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.product-search {
    background-color: #444;
    background: url(/img/decoration-tire.jpg);
    background-size: cover;
    padding: 15px;

    h2 {
        color: #ccc;
        font-size: 15px;
    }
}
</style>